<template>
  <div class="row justify-content-between h-100">
    <div
      class="d-none d-lg-block col-lg-2 col-xl-2 justify-content-between align-items-center bg-primary text-white"
    >
      <PersonalMenu :user="user"></PersonalMenu>
    </div>
    <div class="col-lg-9 col-xl-9">
      <div class="d-flex flex-column">
        <div class="row align-items-center my-10">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column px-10">
              <h1 class="card-label h1 font-weight-bolder">
                {{ $t("PSEDIT.PS") }}
              </h1>
              <h2 class="card-label h3 font-weight-bold">
                {{ $t("PSEDIT.AS") }}
              </h2>
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-10 px-10">
          <div class="col-xl-12">
            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column mr-auto">
                    <h2
                      class="font-size-h2 text-dark font-weight-bolder mb-1 mr-10"
                    >
                      {{ $t("PSEDIT.MODIFICADATI") }} {{ user.name }}
                      {{ user.surname }}
                    </h2>
                  </div>
                  <div class="card-toolbar">
                    <button
                      class="btn btn-primary text-uppercase font-weight-bolder"
                      @click.prevent="submitData"
                    >
                      {{ $t("PSEDIT.SALVA") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column mr-auto">
                    <h2
                      class="font-size-h6 text-dark font-weight-bolder mb-1 mr-10"
                    >
                      {{ $t("PSEDIT.MODPASS") }}
                    </h2>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-xl-6">
                    <b-form-input
                      id="input-password"
                      class="bg-default text-primary form-control-lg"
                      type="password"
                      name="newPassword"
                      v-model="newPassword"
                      v-bind:placeholder="$t('PSEDIT.NUOVAPASSWORD')"
                    ></b-form-input>
                  </div>
                  <div class="col-xl-6">
                    <b-form-input
                      id="input-password-confirm"
                      class="bg-default text-primary form-control-lg"
                      type="password"
                      name="newPasswordConfirm"
                      v-model="newPasswordConfirm"
                      v-bind:placeholder="$t('PSEDIT.CONFERMAPASSWORD')"
                    ></b-form-input>
                  </div>

                  <div class="col-12 col-md-6 mt-5">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.CITIZENSHIP")
                    }}</label>
                    <!-- class="bg-default text-primary form-control form-control-lg" -->
                    <multiselect
                      v-model="country"
                      :options="countries"
                      track-by="id"
                      label="name"
                      :show-labels="false"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <div class="d-flex" style="gap: 15px">
                          <img
                            :src="props.option.flag.uri"
                            style="width: 25px"
                          />
                          <span>{{ props.option.name }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="d-flex" style="gap: 15px">
                          <img
                            :src="props.option.flag.uri"
                            style="width: 25px"
                          />
                          <span>{{ props.option.name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>

                  <div class="col-12 col-md-6 mt-5">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.RESIDENCECITY")
                    }}</label>
                    <gmap-autocomplete
                      :value="residenceAddress"
                      :select-first-on-enter="true"
                      class="form-control form-control-lg"
                      :types="['locality']"
                      @place_changed="setPlace"
                      @change="setPlace"
                    >
                    </gmap-autocomplete>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-5">
                  <div class="d-flex flex-column mr-auto">
                    <h2
                      class="font-size-h6 text-dark font-weight-bolder mb-1 mr-10"
                    >
                      {{ $t("PSEDIT.MODAVATAR") }}
                    </h2>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-xl-6">
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                      :useCustomSlot="true"
                      @vdropzone-success="successUploadAvatar"
                    >
                      <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">
                          {{ $t("PSEDIT.DRAGANDDROP") }}
                        </h3>
                        <div class="subtitle">
                          {{ $t("PSEDIT.FORMSUPPORTATI") }}
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 shadow-sm">
              <b-button
                v-b-toggle.collapse-privacy
                class="btn btn-block btn-white text-primary p-4 text-left"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                    </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">{{
                  $t("PSEDIT.PRIVACY")
                }}</span>
              </b-button>
              <b-collapse visible id="collapse-privacy" class="p-5">
                <div class="row px-5">
                  <div class="col-lg-6 col-sm-12">
                    <label class="checkbox-inline">
                      <input
                        type="checkbox"
                        :checked="profilePublic"
                        v-model="profilePublic"
                      />
                      {{ $t("PSEDIT.PROFILOPUBBLICO") }}
                    </label>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-none d-sm-block col-1 justify-content-between align-items-center"
    >
      <div class="d-flex flex-column mt-10 mr-5 align-items-center">
        <router-link to="">
          <a
            href="#"
            class="mb-10 btn btn-icon w-auto d-flex btn-lg px-2"
            @click.prevent="alertInfo"
            id="pop-info"
          >
            <span class="symbol symbol-rounded">
              <span
                class="symbol-label font-size-h3 font-weight-bold bg-primary text-white"
              >
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="10"
                        width="2"
                        height="7"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="7"
                        width="2"
                        height="2"
                        rx="1"
                      />
                    </g>
                  </svg>
                </span>
              </span>
            </span>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import ProcessCardPreview from "@/view/pages/process/partials/ProcessCardPreview.vue";
// import GroupCardPreview from "@/view/pages/groups/partials/GroupCardPreview";
import PersonalMenu from "@/view/layout/common/PersonalMenu.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";
import {
  GET_REFRESHED_TOKEN,
  REFRESH_TOKEN,
} from "../../core/services/store/auth.module";
import Multiselect from "vue-multiselect";
// import GoogleMaps from "@/core/services/googlemaps.service.js";
import { gmapApi } from "gmap-vue";

export default {
  name: "personalSpaceAccount",
  components: {
    PersonalMenu,
    vueDropzone: vue2Dropzone,
    Multiselect,
  },
  data() {
    return {
      processes: [],
      groups: [],
      user: [],
      newPassword: "",
      newPasswordConfirm: "",
      profilePublic: "",
      avatar: {},
      country: null,
      countries: [],
      residence: null,
      residenceAddress: "",
      geocoder: null,

      /*
      |--------------------------------------------------------------------------
      | AVATAR
      |--------------------------------------------------------------------------
      */
      dropzoneOptions: {
        url:
          this.$baseUrl +
          this.$apiResourceOpen +
          "/resources/image/not_applicable/avatar",
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        acceptedFiles: ".jpg, .jpeg, .png, .gif",

        addRemoveLinks: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
        params: {
          metadata: new Blob(
            [
              JSON.stringify({
                title: "",
                description: "",
              }),
            ],
            {
              type: "application/json",
            }
          ),
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getOrganization", "currentUser"]),
    google: gmapApi,
    getGenderLabel() {
      return this.user.gender == "M"
        ? this.$t("PS.MASCHIO")
        : this.$t("PS.FEMMINA");
    },
    getTermsConditionsLabel() {
      return this.user.termsConditionsAccepted == true ? " checked " : "";
    },
    getPrivacyLabel() {
      return this.user.privacyPolicyAccepted == true ? " checked " : "";
    },
    getNewsletterLabel() {
      return this.user.newsletterAccepted == true ? " checked " : "";
    },
    getPublicPrivateLabel() {
      return this.user.profilePublic == false ? " checked " : "";
    },
  },
  async mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      if (this.google) {
        this.geocoder = new this.google.maps.Geocoder();
      }
    });
    await this.getUser();
    await this.getCountries();
  },
  methods: {
    getUser() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl(
          "users-expanded/" + this.currentUser.id,
          true
        );
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then((res) => {
            this.user = res.data;
            this.profilePublic = this.user.profilePublic;
            this.country = this.user.citizenship;
            this.residenceAddress = this.user.residenceAddress;
            this.$isLoading(false);
            resolve();
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    getCountries() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("countries", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then((res) => {
            this.countries = res.data || [];
            this.$isLoading(false);
            resolve();
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    alertDelete() {
      Swal.fire({
        title: this.$t("PSACCOUNT.ELIMINAACCOUNTTITLE"),
        html: this.$t("PSACCOUNT.ELIMINAACCOUNT"),
        icon: "error",
        confirmButtonClass: "btn btn-danger",
      });
    },
    alertInfo() {
      Swal.fire({
        title: this.$t("HOME.ALERT2TITLE"),
        html: this.$t("HOME.ALERT2"),
        icon: "warning",
        confirmButtonClass: "btn btn-warning",
      });
    },
    successUploadAvatar(file, res) {
      this.avatar = res;
    },
    async submitData() {
      this.$isLoading(true);

      let newPassword = this.newPassword.trim();
      let newPasswordConfirm = this.newPasswordConfirm.trim();

      if (newPassword !== newPasswordConfirm) {
        Swal.fire({
          title: this.$t("PSEDIT.ERRORE"),
          text: this.$t("PSEDIT.ERROREPASS"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        this.$isLoading(false);
        return;
      } else if (newPassword.length > 0) {
        this.user.password = newPassword;
      }

      if (this.avatar.uri) {
        this.user.avatar = this.avatar;
      }

      this.user.profilePublic = this.profilePublic;

      this.user.citizenship = {
        id: this.country ? this.country.id : null,
      };

      let residenceData = {
        id: this.user.id,
        residenceCountry: null,
        residenceCentroid: null,
        citizenship: this.user.citizenship,
        localizations: [],
      };

      const languages = this.getOrganization?.data?.languages || [];
      for (const lang of languages) {
        if (this.residence) {
          const resGeocoder = await this.geocoder.geocode({
            placeId: this.residence.place_id,
            language: lang.locale,
          });

          const gmRes = resGeocoder?.results[0] || null;

          // const gmRes = await GoogleMaps.getByPlaceId(
          //   this.residence.place_id,
          //   lang.locale
          // );

          const locality = gmRes?.address_components.find((x) =>
            x.types.includes("locality")
          );
          const residenceCountry = gmRes?.address_components.find((x) =>
            x.types.includes("country")
          );

          residenceData.residenceCentroid = `POINT (${this.residence.geometry.location.lng()} ${this.residence.geometry.location.lat()})`;

          if (residenceCountry) {
            residenceData.residenceCountry = {
              code: residenceCountry?.short_name,
            };
          }
          residenceData.localizations.push({
            locale: lang.locale,
            residenceCity: locality ? locality?.long_name : "",
            // residenceAddress: gmRes?.formatted_address || "",
            residenceAddress: locality ? locality?.long_name : "",
          });
        } else if (!this.residenceAddress) {
          residenceData.localizations.push({
            locale: lang.locale,
            residenceCity: "",
            residenceAddress: "",
          });
        } else {
          residenceData.localizations = [];
          residenceData.residenceCountry = this.user.residenceCountry;
          residenceData.residenceCentroid = this.user.residenceCentroid;
        }
      }

      return new Promise((resolve, reject) => {
        var endpoint = Vue.prototype.generateUrl(
          "/users-unassociated",
          false,
          false
        );
        ApiService.put(this.$apiResourceOpen + endpoint, this.user)
          .then(async () => {
            let newToken = await this.$store.dispatch(GET_REFRESHED_TOKEN);
            await this.$store.dispatch(REFRESH_TOKEN, newToken.headers.token);

            endpoint = Vue.prototype.generateUrl(
              "/users-unassociated-locsnode/geo",
              false,
              false
            );
            ApiService.put(this.$apiResourceOpen + endpoint, residenceData)
              .then(() => {
                this.$isLoading(false);
                Swal.fire({
                  title: this.$t("PSEDIT.SUCCESSO"),
                  text: this.$t("PSEDIT.MODOK"),
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                }).then(() => {
                  this.$router.push({ path: "/personal-space-account" });
                });
                resolve(1);
              })
              .catch(() => {
                this.$isLoading(false);
                reject(0);
              });
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    setPlace(place) {
      this.residence =
        place.geometry && place.geometry != undefined ? place : null;
      this.residenceAddress = "";
    },
  },
};
</script>
